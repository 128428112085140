// Example locale file for English, give this to your locale team to translate

const agGridLocaleFR = {
    // Set Filter
    selectAll: '(Tout sélectionner)',
    selectAllSearchResults: '(Sélectionner tous les résultats de la recherche)',
    searchOoo: 'Recherche...',
    blanks: '(Vides)',
    noMatches: 'Aucun résultat',

    // Number Filter & Text Filter
    filterOoo: 'Filtrer...',
    equals: 'Equal à',
    notEqual: 'Différent de',
    blank: 'Blank',
    notBlank: 'Not blank',
    empty: 'Choose One',

    // Number Filter
    lessThan: 'Inférieur à',
    greaterThan: 'Supérieur à',
    lessThanOrEqual: 'Inférieur ou équal à',
    greaterThanOrEqual: 'Supérieur ou équal à',
    inRange: 'Compris',
    inRangeStart: 'de',
    inRangeEnd: 'à',

    // Text Filter
    contains: 'Contient',
    notContains: 'Ne contient pas',
    startsWith: 'Commence par',
    endsWith: 'Fini par',

    // Date Filter
    dateFormatOoo: 'yyyy-mm-dd',

    // Filter Conditions
    andCondition: 'ET',
    orCondition: 'OU',

    // Filter Buttons
    applyFilter: 'Appliquer',
    resetFilter: 'Réinitialiser',
    clearFilter: 'Effacer',
    cancelFilter: 'Annuler',

    // Filter Titles
    textFilter: 'Filter par expréssion',
    numberFilter: 'Filter par nombre',
    dateFilter: 'Filter par date',
    setFilter: 'Appliquer un filte',

    // Side Bar
    columns: 'Colonnes',
    filters: 'Filtres',

    // columns tool panel
    pivotMode: 'Mode pivot',
    groups: 'Row Groups',
    rowGroupColumnsEmptyMessage: 'Faites glisser ici pour définir des groupes de lignes',
    values: 'Valeurs',
    valueColumnsEmptyMessage: 'Drag here to aggregate',
    pivots: 'Column Labels',
    pivotColumnsEmptyMessage: 'Faites glisser ici pour définir les entêtes des colonnes',

    // Header of the Default Group Column
    group: 'Groupe',

    // Row Drag
    rowDragRows:'Lignes',

    // Other
    loadingOoo: 'Chargement en cours...',
    noRowsToShow: 'Aucune ligne à afficher',
    enabled: 'Activer',

    // Menu
    pinColumn: 'Fixer la colonne',
    pinLeft: 'Fixer à gauche',
    pinRight: 'Fixer à droite',
    noPin: 'Non fixé',
    valueAggregation: 'Value Aggregation',
    autosizeThiscolumn: 'Auto-dimensionner cette colonne',
    autosizeAllColumns: 'Auto-dimensionner toutes les colonnes',
    groupBy: 'Grouper par',
    ungroupBy: 'Un-Group by',
    addToValues: 'Ajouter ${variable} au valeur',
    removeFromValues: 'Supprimer ${variable} from values',
    addToLabels: 'Ajouter ${variable} to labels',
    removeFromLabels: 'Supprimer ${variable} from labels',
    resetColumns: 'Réinitialiser les colonnes',
    expandAll: 'Expand All',
    collapseAll: 'Tout fermer',
    copy: 'Copier',
    ctrlC: 'Ctrl+C',
    copyWithHeaders: 'Copier avec les entêtes',
    copyWithHeaderGroups: 'Copier avec les entêtes de groupements',
    paste: 'Coller',
    ctrlV: 'Ctrl+V',
    export: 'Exporter',
    csvExport: 'Exporter au format CSV',
    excelExport: 'Export au format Excel',

    // Enterprise Menu Aggregation and Status Bar
    sum: 'Sum',
    min: 'Min',
    max: 'Max',
    none: 'None',
    count: 'Total',
    avg: 'Moyenne',
    filteredRows: 'Filtré',
    selectedRows: 'Sélectionné',
    totalRows: 'Total de lignes',
    totalAndFilteredRows: 'Lignes',
    more: 'Plus',
    to: 'à',
    of: 'sur',
    page: 'Page',
    nextPage: 'Page suivante',
    lastPage: 'Dernière page',
    firstPage: 'Première page',
    previousPage: 'Page précédente',

    // Pivoting
    pivotColumnGroupTotals: 'Total',

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
    pivotChart: 'Pivot graphique',
    chartRange: 'Chart Range',

    columnChart: 'Colonne',
    groupedColumn: 'Groupé',
    stackedColumn: 'Empilé',
    normalizedColumn: '100% Empilé',

    barChart: 'Bar',
    groupedBar: 'Groupé',
    stackedBar: 'Empilé',
    normalizedBar: '100% Empilé',

    pieChart: 'Pie',
    pie: 'Pie',
    doughnut: 'Doughnut',

    line: 'Ligne',

    xyChart: 'X Y (Scatter)',
    scatter: 'Scatter',
    bubble: 'Bulle',

    areaChart: 'Zone',
    area: 'Zone',
    stackedArea: 'Empilée',
    normalizedArea: '100% Empilée',

    histogramChart: 'Histogramme',

    combinationChart: 'Combinaison',
    columnLineCombo: 'Colonne & Ligne',
    AreaColumnCombo: 'Zone & Colonne',

    // Charts
    pivotChartTitle: 'Pivot Chart',
    rangeChartTitle: 'Range Chart',
    settings: 'Paramètre',
    data: 'Data',
    format: 'Format',
    categories: 'Categories',
    defaultCategory: '(Aucune)',
    series: 'Series',
    xyValues: 'Valeurs X Y',
    paired: 'Paired Mode',
    axis: 'Axis',
    navigator: 'Navigator',
    color: 'Couleur',
    thickness: 'Epaisseur',
    xType: 'X Type',
    automatic: 'Automatique',
    category: 'Categorie',
    number: 'Nombre',
    time: 'Heure',
    xRotation: 'X Rotation',
    yRotation: 'Y Rotation',
    ticks: 'Ticks',
    width: 'Width',
    height: 'Height',
    length: 'Length',
    padding: 'Padding',
    spacing: 'Spacing',
    chart: 'Chart',
    title: 'Title',
    titlePlaceholder: 'Titre du graphique - double clic pour modifier',
    background: 'Fond',
    font: 'Font',
    top: 'Haut',
    right: 'Droite',
    bottom: 'Bas',
    left: 'Gauche',
    labels: 'Libelé',
    size: 'Taille',
    minSize: 'Taille minimum',
    maxSize: 'Taille maximum',
    legend: 'Légende',
    position: 'Position',
    markerSize: 'Taille du marqueur',
    markerStroke: 'Epaisseur du marqueur',
    markerPadding: 'Marge du marqueur',
    itemSpacing: 'Marge des éléments',
    itemPaddingX: 'Marge X',
    itemPaddingY: 'Marge Y',
    layoutHorizontalSpacing: 'Marge horizontal',
    layoutVerticalSpacing: 'Marge vertical',
    strokeWidth: 'Epaisseur',
    offset: 'Offset',
    offsets: 'Offsets',
    tooltips: 'Infobulle',
    callout: 'Callout',
    markers: 'Marqueurs',
    shadow: 'Ombre',
    blur: 'Flou',
    xOffset: 'X Offset',
    yOffset: 'Y Offset',
    lineWidth: 'Line Width',
    normal: 'Normal',
    bold: 'Bold',
    italic: 'Italic',
    boldItalic: 'Bold Italic',
    predefined: 'Prédéfini',
    fillOpacity: 'Fill Opacity',
    strokeOpacity: 'Line Opacity',
    histogramBinCount: 'Bin count',
    columnGroup: 'Colonne',
    barGroup: 'Bar',
    pieGroup: 'Pie',
    lineGroup: 'Ligne',
    scatterGroup: 'X Y (Scatter)',
    areaGroup: 'Zone',
    histogramGroup: 'Histogramme',
    combinationGroup: 'Combination',
    groupedColumnTooltip: 'Groupé',
    stackedColumnTooltip: 'Empilé',
    normalizedColumnTooltip: '100% Empilé',
    groupedBarTooltip: 'Groupé',
    stackedBarTooltip: 'Empilé',
    normalizedBarTooltip: '100% Empilé"',
    pieTooltip: 'Pie',
    doughnutTooltip: 'Doughnut',
    lineTooltip: 'Ligne',
    groupedAreaTooltip: 'Zone',
    stackedAreaTooltip: 'Empilé',
    normalizedAreaTooltip: '100% Empilé',
    scatterTooltip: 'Scatter',
    bubbleTooltip: 'Bulle',
    histogramTooltip: 'Histogramme',
    columnLineComboTooltip: 'Ligne & Colonne',
    areaColumnComboTooltip: 'Zone & Colonne',
    customComboTooltip: 'Combinaison personnalisée',
    noDataToChart: 'Aucune donnée disponible pour une sortie graphique',
    pivotChartRequiresPivotMode: 'Pivot graphique nécessite que le pivot mode soit activé.',
    chartSettingsToolbarTooltip: 'Menu',
    chartLinkToolbarTooltip: 'Lié au tableau',
    chartUnlinkToolbarTooltip: 'Délié au tableau',
    chartDownloadToolbarTooltip: 'Télécharger le graphique',
    seriesChartType: 'Type de séries de graphique',
    seriesType: 'Type de série',
    secondaryAxis: 'Axe secondaire',

    // ARIA
    ariaHidden: 'masquer',
    ariaVisible: 'afficher',
    ariaChecked: 'checked',
    ariaUnchecked: 'unchecked',
    ariaIndeterminate:'indeterminate',
    ariaDefaultListName: 'Liste',
    ariaColumnSelectAll: 'Toggle Select All Columns',
    ariaInputEditor: 'Champ éditable',
    ariaDateFilterInput: 'Date Filter Input',
    ariaFilterList: 'Filter List',
    ariaFilterInput: 'Filter Input',
    ariaFilterColumnsInput: 'Filter Columns Input',
    ariaFilterValue: 'Filter Value',
    ariaFilterFromValue: 'Filter from value',
    ariaFilterToValue: 'Filter to value',
    ariaFilteringOperator: 'Filtering Operator',
    ariaColumn: 'Colonne',
    ariaColumnList: 'Liste des colonnes',
    ariaColumnGroup: 'Groupe des colonnes',
    ariaRowSelect: 'Press ESPACE to select this row',
    ariaRowDeselect: 'Press ESPACE to deselect this row',
    ariaRowToggleSelection: 'Press ESPACE to toggle row selection',
    ariaRowSelectAll: 'Press ESPACE to toggle all rows selection',
    ariaToggleVisibility: 'Press ESPACE to toggle visibility',
    ariaSearch: 'Recherche',
    ariaSearchFilterValues: 'Search filter values',

    ariaRowGroupDropZonePanelLabel: 'Row Groups',
    ariaValuesDropZonePanelLabel: 'Valeurs',
    ariaPivotDropZonePanelLabel: 'Libellé des colonnes',
    ariaDropZoneColumnComponentDescription: 'Press DELETE pour supprimer',
    ariaDropZoneColumnValueItemDescription: 'Press ENTER to change the aggregation type',

    // ARIA Labels for Dialogs
    ariaLabelColumnMenu: 'Column Menu',
    ariaLabelCellEditor: 'Cell Editor',
    ariaLabelDialog: 'Dialog',
    ariaLabelSelectField: 'Select Field',
    ariaLabelTooltip: 'Tooltip',
    ariaLabelContextMenu: 'Context Menu',
    ariaLabelSubMenu: 'SubMenu',
    ariaLabelAggregationFunction: 'Aggregation Function',

    // Number Format (Status Bar, Pagination Panel)
    thousandSeparator: ',',
    decimalSeparator: '.'

}
export {agGridLocaleFR}